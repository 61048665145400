var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "login-home" } },
    [
      _c(
        "v-main",
        { staticClass: "fill-height" },
        [
          _c(
            "transition",
            { attrs: { mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }